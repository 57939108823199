.cdk-overlay-container,
mat-datepicker-content {
  z-index: 3000;
}

.mat-mdc-select-panel {
  min-width: 100% !important;
  width: 100% !important;
}

// cdk-drag
.cdk-drag-placeholder {
  opacity: 0.2;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
